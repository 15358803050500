import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux';
import { addReelAppUsers } from "../../store/actions/reelAppsActions";
import { Link, useHistory } from "react-router-dom";

const AddUser = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const memberships = useSelector(state => state.reelapps.memberships) || [];
  const currentApp = useSelector(state => state.app.currentApp);
  // const [credit, setUser] = useState(0)
  let [loader, setLoader] = useState({
    loader: false,
    buttonText: 'Submit'
  });
  // const getCreditValue = () => {
  //   memberships?.map((mem_level) => {
  //     return
  //   })
  // }

  let [user, setUser] = useState({
    name: false,
    secret_key: useSelector(state => state.app.currentApp.secret_key),
    email: false,
    password: false,
    account_tag: false,
    user_type: '',
    membership: [],
    send_email: true,
    send_gr_email: true,
    numberOfMinutes: '',
    totalCredit: 0,
    total_credit: 0,
  });
  const getInputValue = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const getCheckEmail = (e) => {
    if (user.send_email === false) {
      setUser({ ...user, send_email: true });
    } else {
      setUser({ ...user, send_email: false });
    }
  };

  const getCheckGR = (e) => {
    if (user.send_gr_email === false) {
      setUser({ ...user, send_gr_email: true });
    } else {
      setUser({ ...user, send_gr_email: false });
    }
  };

  const memLevel = (e) => {
    let array = user.membership;

    if (array.includes(e.target.value)) {
      array.splice(array.indexOf(e.target.value), 1);
    } else {
      array.push(e.target.value);
    }
    setUser({ ...user, membership: array });

  };


  const onFormSubmit = (e) => {

    e.preventDefault();
    if (user.membership.length > 0 || memberships.length <= 0) {
      const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
      if (pattern.test(user.password)) {
        setLoader({
          ...loader,
          loader: true,
          buttonText: 'Adding...'
        });
        dispatch(addReelAppUsers(user, setLoader, currentApp));

        history.push('/view-user');
      } else {
        dispatch({ type: 'ERROR', payload: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long." })
      }
    } else {
      dispatch({ type: 'ERROR', payload: "Please select membership" });
    }
  };

  useEffect(() => {
    let totalCredit = 0;
    let influencerCredit = 0

    if (user.membership.length > 1) {
      totalCredit = 50000;
      influencerCredit = 50000
    } else if (user.membership.includes("premium")) {
      totalCredit = 25000;
      influencerCredit = 10000;
    } else if (user.membership.includes("ultimate")) {
      totalCredit = 50000;
      influencerCredit = 50000;
    } else if (
      user.membership.includes("unlimited") ||
      user.membership.includes("unlimited-Lite") ||
      user.membership.includes("enterprise") ||
      user.membership.includes("professional") ||
      user.membership.includes("professional-lite") ||
      user.membership.includes("fastpass") ||
      user.membership.includes("megapass") ||
      user.membership.includes("montizer") ||
      user.membership.includes("full-access")
    ) {
      totalCredit = 50000;
      influencerCredit = 50000;
    }

    setUser(prevUser => ({
      ...prevUser,
      totalCredit: totalCredit,
      total_credit: influencerCredit,
    }));

  }, [user.membership.length]);



  return (
    <div>
      <Helmet>
        <title> Super-Admin | Add User</title>
      </Helmet>
      <section >
        <div>
          <div id="app">
            <div className="main-wrapper main-wrapper-1">
              <div className="navbar-bg" />

              {/* Main Content */}
              <div className="main-content">
                <section className="section">
                  <div className="section-header">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="section-header-breadcrumb-content">
                          <h1>Add User</h1>
                          <div className="section-header-breadcrumb">
                            <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                            <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                            <div className="breadcrumb-item"><a href="#">Add User</a></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                      </div>
                    </div>
                  </div>
                  <div className="row">


                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title-wrap bar-success">
                            <h4 className="card-title mb-0">Add User</h4>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="px-3">
                            <form action="#" method="post" acceptCharset="utf-8" onSubmit={e => onFormSubmit(e)}>
                              <div className="form-body">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Name:</label>
                                      <input type="text" id="helperText" name="name" className="form-control" placeholder="First Name" onChange={(e) => getInputValue(e)} required />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Email:</label>
                                      <input type="email" id="helperText" name="email" className="form-control" placeholder="abc@xyz.com" required onChange={(e) => getInputValue(e)} />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Password:</label>
                                      <input type="password" id="helperText" name="password" className="form-control" placeholder="*******" required onChange={(e) => getInputValue(e)} />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Account Tag:</label>
                                      <input type="text" id="helperText" name="account_tag" className="form-control" placeholder="eg. affiliate" required onChange={(e) => getInputValue(e)} />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">User Type:</label>
                                      <select id="select" name="user_type" className="form-control" onChange={(e) => getInputValue(e)} value={user.user_type} required>
                                        <option value="" selected disabled hidden>-user type-</option>
                                        <option value="is_super_admin">Super Admin</option>
                                        <option value="is_user">User</option>
                                        <option value="is_virtual_account">Virtual</option>
                                        <option value="is_client_account">client</option>
                                        <option value="is_trial">Trial</option>
                                      </select>
                                    </fieldset>
                                  </div>
                                  {currentApp.name === "AiCalling" ? <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label className="label" htmlFor="helperText">Budget ( minutes )</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="numberOfMinutes"
                                        value={user.numberOfMinutes}

                                        placeholder='Enter Minutes'
                                        onChange={(e) => getInputValue(e)}
                                        min={0}
                                      // required
                                      />
                                    </fieldset>
                                  </div> : ""}

                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    {memberships.length > 0 ?
                                      <fieldset className="form-group text-left">
                                        <label htmlFor="helperText">Membership Levels:</label> <br />
                                        {
                                          currentApp.name === 'AIsocial' ?
                                            memberships?.map((mem_level, index) => {
                                              return (
                                                <>
                                                  <input type="checkbox"
                                                    key={mem_level.name ? mem_level.name : mem_level.title}
                                                    id="helperText"
                                                    className="mr-1"
                                                    name={mem_level.title}
                                                    onChange={(e) => memLevel(e)}
                                                    defaultValue={mem_level.name ? mem_level.name : mem_level.title} />
                                                  {mem_level.title === "Premium" ? "Elite" : mem_level.title}
                                                  <br />
                                                </>
                                              );
                                            }) : memberships?.map((mem_level, index) => {

                                              return (
                                                <>
                                                  <input type="checkbox"
                                                    key={mem_level.name ? mem_level.name : mem_level.title}
                                                    id="helperText"
                                                    className="mr-1"
                                                    // checked={mem.includes("15")}
                                                    onChange={(e) => memLevel(e)}
                                                    defaultValue={mem_level.name ? mem_level.name : mem_level.title} />
                                                  {mem_level.title}
                                                  <br />
                                                </>
                                              );
                                            })
                                        }
                                      </fieldset> : ""}
                                  </div>
                                  {currentApp.name === "AiOffices" ? <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label className="label" htmlFor="helperText">Conversation Credits</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="totalCredit"
                                        value={user.totalCredit}
                                        placeholder='Enter Credits'
                                        readOnly
                                      />
                                    </fieldset>
                                  </div> : ""}
                                  {currentApp.name === "AiInfluencers" ? <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label className="label" htmlFor="helperText">Credits</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="total_credit"
                                        value={user.total_credit}
                                        placeholder='Enter Credits'
                                        readOnly
                                      // className="form-control"
                                      // type="number"
                                      // name="total_credit"
                                      // value={user.total_credit}

                                      // placeholder='Enter Credits'
                                      // onChange={(e) => getInputValue(e)}
                                      // min={0}
                                      // required
                                      />
                                    </fieldset>
                                  </div> : ""}
                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Send Email TO User:</label> <br />
                                      <input type="checkbox" id="helperTextemail" name="send_email" defaultValue={user.send_email} defaultChecked onChange={(e) => getCheckEmail(e)} /> Send Email<br />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Add Email to Get Response:</label> <br />
                                      <input type="checkbox" id="helperText" name="send_gr_email" defaultValue={user.send_gr_email} defaultChecked onChange={(e) => getCheckGR(e)} /> Get Response Email<br />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <button type="submit" className="btn btn-primary pull-right">
                                        {loader.loader ? <i className="fa fa-spinner fa-spin" /> : ''} {loader.buttonText}
                                      </button>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AddUser;
